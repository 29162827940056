
import getColor from 'utils/getThemeColor'

const colors = {};

const colorVals: ObjectOf<colorVal> = {
  green: [
    {stop: 0, h: 127, s: 47},
    {stop: 100, h: 127, s: 47}
  ],
  gray: [
    {stop: 0, h: 0, s: 0},
    {stop: 100, h: 0, s: 0}
  ],
  oasisBlue: [
    {stop: 0, h: 218, s: 100},
    {stop: 100, h: 218, s: 100}
  ],
  ormuzGreen: [
    {stop: 0, h: 135, s: 44},
    {stop: 100, h: 135, s: 44}
  ],
  merchantsYellow: [
    {stop: 0, h: 49, s: 100},
    {stop: 100, h: 49, s: 100}
  ],
  colosseumPurple: [
    {stop: 0, h: 249, s: 68},
    {stop: 100, h: 249, s: 68}
  ],
  edenGreen: [
    {stop: 0, h: 147, s: 96},
    {stop: 100, h: 147, s: 96}
  ]
};



const breakpoints = [0, 480, 768, 1000, 1300, 1600, 1900];

const spacing = {
  outerGutterWidth: ['1rem', '1rem', '1.5rem', '1.5rem', '2rem', '2rem'],
  pageWrapperWidth: ['0', '0', '0', '0', '0', '100rem']
};

const typography = {
  fontFamilyDefault: '"Inter", Arial, Helvetica, sans-serif',
  fontFamilySecondary: '"Roboto Condensed", Arial, Helvetica, sans-serif'
};

const theme = {
  typography, 
  colors, 
  spacing, 
  breakpoints
};

const color = {
  get: getColor,
  green: (lightness) => getColor(colorVals.green, lightness),
  gray: (lightness) => getColor(colorVals.gray, lightness),
  oasisBlue: (lightness) => getColor(colorVals.oasisBlue, lightness),
  ormuzGreen: (lightness) => getColor(colorVals.ormuzGreen, lightness),
  merchantsYellow: (lightness) => getColor(colorVals.merchantsYellow, lightness),
  colosseumPurple: (lightness) => getColor(colorVals.colosseumPurple, lightness),
  edenGreen: (lightness) => getColor(colorVals.edenGreen, lightness)
};


const bp = (index: number, upto = false) => {
  return breakpoints[upto ? index : index-1] + 'px'
};

export {bp, color};

export default theme; 