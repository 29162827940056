import { useRouter } from 'next/router';
import Head from 'next/head';
import { trpc } from 'utils/trpc';
import { Toaster } from 'react-hot-toast';
import { LayoutProvider } from 'providers/layout/layoutProvider';

import GlobalStyle from 'styles/globalstyles';

import { Figtree } from 'next/font/google';

const figtree = Figtree({ subsets: ['latin'] });

type AppPropsExtended<P = {}> = (import('next/app').AppProps<P>) & {
  Component: NextPageExtended<P>
}

const MyApp = ({ Component, pageProps }: AppPropsExtended<{session: any}>) => {

  const getLayout = Component.getLayout ?? ((page) => page);
  const route = useRouter().pathname;

  return (
    <LayoutProvider>

      <Head>
        <meta name="viewport" content='initial-scale=1.0, width=device-width, height=device-height' />
      </Head>

      <style jsx global>{`
        html {
          font-family: ${figtree.style.fontFamily};
        }
      `}</style>

      <GlobalStyle />
      <Toaster position='bottom-right' />

      {getLayout(<Component key={route} {...pageProps}></Component>)}

    </LayoutProvider>
  );

}

export default trpc.withTRPC(MyApp);